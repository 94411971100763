import React, { ElementType, FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ArticleCard from '@kastle/ui/src/molecules/ArticleCard/ArticleCard';
import { HeadingTypes } from '@kastle/ui/src/atoms/Heading/Heading.types';

type Props = {
  title: string;
  description: string;
  image: IGatsbyImageData;
  path: string;
  as?: ElementType;
};

const ArticlePreview: FC<Props> = ({
  title,
  description,
  image,
  path,
  as = HeadingTypes.h2,
}) => (
  <ArticleCard>
    <Link to={path} aria-label={title}>
      {image ? (
        <ArticleCard.Image
          as={GatsbyImage}
          image={image}
          objectFit="cover"
          objectPosition="50% 50%"
          style={{ height: '300px' }}
        />
      ) : null}
    </Link>
    <ArticleCard.Content>
      <Link to={path} aria-label={title}>
        <ArticleCard.Title as={as}>{title}</ArticleCard.Title>
      </Link>
      <ArticleCard.Description>{description}</ArticleCard.Description>
    </ArticleCard.Content>
  </ArticleCard>
);

export default ArticlePreview;
