import React, { FC } from 'react';
import { PageContext } from '../../../../types';
import { CommonLayout } from './Common';
import withI18next from '../../../../i18n/I18n';
import { useTranslation } from 'react-i18next';
import { TFunction, i18n as I18n } from 'i18next';

enum PageContextType {
  BLOG = 'blog',
  TAG = 'tag',
  CATEGORY = 'category',
  PORTFOLIO = 'portfolio',
}

type ContentListContext = PageContext & {
  type: PageContextType;
  category?: string;
  tag?: string;
  humanPageNumber: number;
  numberOfPages: number;
};

type Props = {
  pageContext: ContentListContext;
  path: string;
};

const getContentByType = (
  type: PageContextType,
  t: TFunction,
  i18n: I18n,
  scope?: string
): [string, string] => {
  const title = t(
    `contents.${type}.list.${scope}.title`,
    t(`contents.${type}.default.title`, { context: scope })
  );

  const description = t(
    `contents.${type}.list.${scope}.description`,
    t(`contents.${type}.default.description`)
  );

  return [title, description];
};

const useContent = (pageContext: ContentListContext): [string, string] => {
  const { t, i18n } = useTranslation();

  switch (pageContext.type) {
    case PageContextType.BLOG:
      return getContentByType(pageContext.type, t, i18n);
    case PageContextType.TAG:
      return getContentByType(
        pageContext.type,
        t,
        i18n,
        pageContext.tag?.toLowerCase()
      );
    case PageContextType.CATEGORY:
      return getContentByType(
        pageContext.type,
        t,
        i18n,
        pageContext.category?.toLowerCase()
      );
    default:
      return getContentByType(pageContext.type, t, i18n);
  }
};

const ArticleList: FC<Props> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const position = t('contents.page', {
    current: props.pageContext.humanPageNumber,
    maximum: props.pageContext.numberOfPages,
  });

  const [title, description] = useContent(props.pageContext);
  return (
    <CommonLayout
      {...props}
      title={title}
      description={description}
      foot={position}
    >
      {children}
    </CommonLayout>
  );
};

export default withI18next(ArticleList);
