import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const ContentItem: FC = ({ children }) => (
  <div className="flex my-2 px-2 w-full overflow-hidden lg:my-4 lg:px-4 lg:w-1/3 justify-center items-center">
    {children}
  </div>
);

export const ContentList: FC<Props> = ({ children, className = '' }) => (
  <div
    className={
      'w-full flex flex-wrap -mx-2 overflow-hidden lg:-mx-4 justify-center ' +
      className
    }
  >
    {children}
  </div>
);
