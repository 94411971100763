import React, { FC } from 'react';
import MdxRenderer from '@kastle/mdx/src/components/Layout/MdxRenderer';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import HeaderComponent from '../../../../components/Header/Header';
import FooterComponent from '../../../../components/Footer/Footer';
import withI18next from '../../../../i18n/I18n';
import { PageContext } from '../../../../types';
import ThemeContextProvider from '../../../../../../components/src/components/Theme/Theme';
import PageSEO from '@kastle/mdx/src/components/SEO/Page';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import { HeadingTypes } from '@kastle/ui//src/atoms/Heading/Heading.types';
import Text from '@kastle/ui/src/atoms/Text/Text';
import { FadeInAnimation } from '@kastle/ui/src/atoms/Animation/FadeIn';

type Props = {
  pageContext: PageContext;
  path: string;
  title?: string;
  description?: string;
  foot?: string;
  useSEO: boolean;
  location: Location;
};

export const CommonLayout: FC<Props> = ({
  children,
  pageContext,
  path,
  title,
  description,
  foot,
  useSEO = true,
  location,
}) => {
  const animation = location.action === undefined;

  return (
    <ThemeContextProvider lightColor="#ffffff" darkColor="#000000">
      {useSEO ? (
        <PageSEO
          lang={pageContext.langKey}
          title={title ? title : pageContext?.frontmatter?.title}
          description={
            description ? description : pageContext?.frontmatter?.title
          }
          path={path}
        />
      ) : null}
      <div className="font-secondary">
        <HeaderComponent
          animation={animation}
          fillEmptySpace={true}
          transparentMode={false}
          pageContext={pageContext}
        />
        <Section type={SectionType.Secondary} className={FadeInAnimation.THIRD}>
          {title ? <Heading as={HeadingTypes.h1}>{title}</Heading> : null}
          {description ? <Text>{description}</Text> : null}
          <MdxRenderer>{children}</MdxRenderer>
          {foot ? <Text>{foot}</Text> : null}
        </Section>
        <FooterComponent pageContext={pageContext} animation={animation} />
      </div>
    </ThemeContextProvider>
  );
};

export default withI18next(CommonLayout);
