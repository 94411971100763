import React, { ElementType, FC } from 'react';
import {
  ContentItem,
  ContentList,
} from '@kastle/ui/src/molecules/ContentDisplay/ContentList';
import ArticlePreview from './ArticlePreview';
import { Article } from '../../types/article';

type Props = {
  articles: Article[];
  as: ElementType;
};

const ArticleContentList: FC<Props> = ({ articles, as }) => (
  <ContentList>
    {articles.map((article) => {
      return (
        <ContentItem
          key={`${article.frontmatter.title}-${article.fields.path}`}
        >
          <ArticlePreview
            title={article.frontmatter.title}
            image={
              article.frontmatter.thumbnail?.childImageSharp.gatsbyImageData
            }
            description={article.excerpt}
            path={article.fields.path}
            as={as}
          />
        </ContentItem>
      );
    })}
  </ContentList>
);

export default ArticleContentList;
