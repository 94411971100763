import React, { FC } from 'react';
import Pagination from '@kastle/ui/src/molecules/Pagination/Pagination';
import { Link } from 'gatsby';

type Props = {
  previousPagePath: string;
  nextPagePath: string;
};

const Pager: FC<Props> = ({ previousPagePath, nextPagePath }) => {
  return (
    <Pagination>
      <Pagination.Previous as={Link} to={previousPagePath} />
      <Pagination.Next as={Link} to={nextPagePath} />
    </Pagination>
  );
};

export default Pager;
