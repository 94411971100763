import React, { FC } from 'react';
import cx from 'classnames';
import Link from '../../atoms/Link/Link';

const elementStyle =
  'relative inline-flex items-center px-2 py-2 border-gray-300 text-sm leading-5 font-medium text-black dark:text-white hover:text-br-1-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150';

type LinkProps = Partial<React.ComponentProps<typeof Link>>;

const Item: FC<LinkProps> = ({ className, children, ...props }) => (
  <Link
    defaultStyle={false}
    className={cx(
      '-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-secondary text-black dark:text-white hover:text-br-1-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-br-1-600 active:text-white dark:active:text-black transition ease-in-out duration-150',
      className
    )}
    {...props}
  >
    {children}
  </Link>
);

const Separator: FC = () => (
  <span className="-ml-px relative inline-flex items-center px-4 py-2 border font-secondary text-black dark:text-white border-gray-300 text-sm leading-5 select-none">
    ...
  </span>
);

const Previous: FC<LinkProps> = ({ className, ...props }) => (
  <Link
    className={cx(
      'rounded-l-md border-t border-b border-l',
      elementStyle,
      className
    )}
    aria-label="Previous"
    defaultStyle={false}
    {...props}
  >
    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  </Link>
);

const Next: FC<LinkProps> = ({ className, ...props }) => (
  <Link
    defaultStyle={false}
    className={cx(
      'rounded-r-md border-t border-b border-r',
      elementStyle,
      className
    )}
    aria-label="Next"
    {...props}
  >
    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </svg>
  </Link>
);

const Desktop: FC = ({ children }) => (
  <div className="hidden sm:flex-1 sm:flex">{children}</div>
);

const Mobile: FC = ({ children }) => (
  <div className="flex flex-1 sm:hidden">{children}</div>
);

const Pagination: FC & {
  Item: FC<LinkProps>;
  Separator: FC;
  Previous: FC<LinkProps>;
  Next: FC<LinkProps>;
  Desktop: FC;
  Mobile: FC;
} = ({ children }) => (
  <nav className="relative inline-flex shadow-sm">{children}</nav>
);

Pagination.Item = Item;
Pagination.Separator = Separator;
Pagination.Previous = Previous;
Pagination.Next = Next;
Pagination.Desktop = Desktop;
Pagination.Mobile = Mobile;

export default Pagination;
